import { PUBLIC_PREVIEW_MODE } from '$env/static/public';
import type { ClientConfig } from '@sanity/client';
import { createClient } from '@sanity/client';

// TODO: use the groq template literal and types from Sanity typegen
// https://www.sanity.io/learn/course/typescripted-content/generating-type-for-groq-query-results
// https://www.sanity.io/learn/course/typescripted-content/adding-generated-types-to-your-front-end

/** If preview mode is activated for the build */
export const previewModeActivated = PUBLIC_PREVIEW_MODE === 'true';

export const sanityGROQPortableTextQuery = `[]{...,markDefs[]{...,
		_type=="internalLink"=>{"slug":@.reference->slug},"documentType": @.reference->_type},
	_type=="image"=>{"imageRecord":asset->}
}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StandardGROQType = ({ _type: string; [key: string]: any } | SanityImageField)[];

export interface SanityImageField {
	_type: 'image';
	asset: {
		_ref: string;
	};
	crop?: { bottom: number; left: number; right: number; top: number };
	hotspot?: { height: number; width: number; x: number; y: number };
	imageRecord?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
		metadata: { dimensions: { aspectRatio: number; height: number; width: number } };
	};
}

/** Shared options for all Sanity clients (server and browser side) */
export const sanityOptionsShared: Readonly<ClientConfig> = {
	projectId: 'frh70i3u',
	dataset: 'production',
	apiVersion: '2022-05-18',
	useCdn: true
};

/** a client that can safely be used on the frontend */
export const client = createClient(sanityOptionsShared);

type ImageRecord = {
	asset: { _ref: string };
	crop?: {
		bottom: number;
		left: number;
		right: number;
		top: number;
	};
	hotspot?: {
		height: number;
		width: number;
		x: number;
		y: number;
	};
};

import imageUrlBuilder from '@sanity/image-url';
const imageBuilder = imageUrlBuilder(client);
/** Create an image from a Sanity image record */
export function imageFrom(source: ImageRecord) {
	return imageBuilder.image(source);
}
